import React from 'react';

import styles from './HowItWorks.module.scss';
import { Section } from '../../../components/Page';

type Props = {
  title: string;
  subtitle: React.ReactNode;
  subtitle2: React.ReactNode;
};

const HowItWorksHeaderSection = (props: Props) => (
  <Section className={styles.headerSection}>
    <div className={styles.headerSectionContent}>
      <h1 className={styles.h1}>{props.title}</h1>
      <div className={styles.subtitleContainer}>
        <p className={styles.subtitle}>{props.subtitle}</p>
        <p>{props.subtitle2}</p>
      </div>
    </div>
  </Section>
);

export default HowItWorksHeaderSection;
