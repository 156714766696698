import React from 'react';
import classNames from 'classnames';

import completeMissions from './media/complete-missions.png';
import completeMissions2x from './media/complete-missions2x.png';
import completeMissions3x from './media/complete-missions3x.png';
import joinGame from './media/join-game.png';
import joinGame2x from './media/join-game2x.png';
import joinGame3x from './media/join-game3x.png';
import saveMemories from './media/save-memories.png';
import saveMemories2x from './media/save-memories2x.png';
import saveMemories3x from './media/save-memories3x.png';
import seeAction from './media/see-action.png';
import seeAction2x from './media/see-action2x.png';
import seeAction3x from './media/see-action3x.png';
import AppGoose from './media/app-goose.svg';

import styles from './HIWPlaying.module.scss';
import howItWorksStyles from '../components/HowItWorks.module.scss';
import Page, { Section } from '../../../components/Page';
import Link from '../../../components/Link';
import routes from '../../../routes';
import Image from '../../../components/Image';
import HowItWorksHeaderSection from '../components/HowItWorksHeaderSection';
import Flex from '../../../components/layout/Flex';
import HowItWorksImgColumn from '../components/HowItWorksImgColumn';
import TryItNowCTA from '../../../components/TryItNowCTA';
import AppStoreLink from '../../../components/AppStoreLink';

const HIWPlaying = () => (
  <Page
    title="How to Participate in a Goosechase Experience | Goosechase"
    description="Joining a Goosechase Experience is super easy! Follow these five simple steps to enjoy your first Experience."
  >
    <HowItWorksHeaderSection
      title="How to join a Goosechase Experience"
      subtitle="Joining in on the fun couldn't be easier. 5 steps is all it takes to start enjoying your first Goosechase Experience."
      subtitle2={
        <React.Fragment>
          Need to learn how to create and run a Goosechase Experience?{' '}
          <Link variant="underline" href={routes.HIW_ORGANIZING.path} useRouter thin>
            Go here
          </Link>
        </React.Fragment>
      }
    />

    <Section paddingBot="none" className={styles.getAppSection}>
      <Flex alignItems="center" justifyContent="center" wrap="wrap" className={howItWorksStyles.bottomAnchoredContent}>
        <div className={howItWorksStyles.column}>
          <h2 className={howItWorksStyles.h2}>First, get the app</h2>
          <p className={howItWorksStyles.paragraph}>
            If you haven&apos;t already, download the Goosechase app. Search ‘Goosechase’ on the App Store or Google
            Play, or click to download from the buttons below:
          </p>
          <div className={styles.appStoreBadges}>
            <AppStoreLink variant="apple" className={styles.appBadge} />
            <AppStoreLink variant="google" className={styles.appBadge} />
          </div>
        </div>
        <HowItWorksImgColumn align="center" padLeft padRight removeBotPadding>
          <AppGoose className={styles.downloadGeeseImg} />
        </HowItWorksImgColumn>
      </Flex>
    </Section>

    <Section padding="none">
      <Flex
        alignItems="center"
        justifyContent="center"
        wrap="wrap-reverse"
        className={howItWorksStyles.bottomAnchoredContent}
      >
        <HowItWorksImgColumn align="center" padLeft padRight removeBotPadding>
          <Image
            className={styles.joinGameImg}
            src={joinGame}
            srcSet={`${joinGame2x} 2x, ${joinGame3x} 3x`}
            width="509"
            height="490"
            alt="join the experience"
          />
        </HowItWorksImgColumn>
        <div className={classNames(howItWorksStyles.column, howItWorksStyles.columnPadLeft)}>
          <div>
            <h2 className={howItWorksStyles.h2}>Join the Experience</h2>
            <p className={howItWorksStyles.paragraph}>
              Once in the app, choose to play as a guest, create an account, or log in.
              <p className={howItWorksStyles.paragraph}>
                Next, search for the name of the Experience or enter the unique join code. Select how you'll appear in
                the game - as an individual player or as part of a team, and away you go!
              </p>
            </p>
          </div>
        </div>
      </Flex>
    </Section>

    <Section padding="none" className={styles.completeMissionSection}>
      <Flex alignItems="center" justifyContent="center" wrap="wrap" className={howItWorksStyles.bottomAnchoredContent}>
        <div className={howItWorksStyles.column}>
          <h2 className={howItWorksStyles.h2}>Complete Missions</h2>
          <p className={howItWorksStyles.paragraph}>
            To earn points, complete Missions from the list. Mission types include photos, videos, checking in at
            specific locations, or answering questions and riddles.
          </p>
          <p className={howItWorksStyles.paragraph}>
            The Experience creator can review all submissions and award bonus points, so put your best feathers forward!
          </p>
        </div>
        <HowItWorksImgColumn align="center" padLeft padRight removeBotPadding>
          <Image
            className={styles.completeMissionsImg}
            src={completeMissions}
            srcSet={`${completeMissions2x} 2x, ${completeMissions3x} 3x`}
            width="464"
            height="564"
            alt="complete missions"
          />
        </HowItWorksImgColumn>
      </Flex>
    </Section>

    <Section padding="none">
      <Flex
        alignItems="center"
        justifyContent="center"
        wrap="wrap-reverse"
        className={howItWorksStyles.bottomAnchoredContent}
      >
        <HowItWorksImgColumn align="center" padLeft padRight removeBotPadding>
          <Image
            className={styles.seeActionImg}
            src={seeAction}
            srcSet={`${seeAction2x} 2x, ${seeAction3x} 3x`}
            width="379"
            height="462"
            alt="see the action live"
          />
        </HowItWorksImgColumn>
        <div className={classNames(howItWorksStyles.column, howItWorksStyles.columnPadLeft)}>
          <h2 className={howItWorksStyles.h2}>See the action... live!</h2>
          <p className={howItWorksStyles.paragraph}>
            Scope out the live leaderboard to see how the Experience is progressing. You can pull inspiration from what
            others are doing, or give big thumbs up to the submissions you think are awesome.
          </p>
        </div>
      </Flex>
    </Section>

    <Section padding="none" className={styles.celebrateSection}>
      <Flex alignItems="center" justifyContent="center" wrap="wrap" className={styles.saveMemContent}>
        <div className={howItWorksStyles.column}>
          <h2 className={howItWorksStyles.h2}>Celebrate, share, repeat</h2>
          <p className={howItWorksStyles.paragraph}>
            The Experience may be over, but the good times keep rolling. Scroll through the activity feed to see
            everyone's submissions, toss out some likes, and bond with other participants!
          </p>
        </div>
        <HowItWorksImgColumn align="center" padLeft padRight>
          <Image
            className={styles.saveMemoriesImg}
            src={saveMemories}
            srcSet={`${saveMemories2x} 2x, ${saveMemories3x} 3x`}
            width="486"
            height="506"
            alt="save the memories"
          />
        </HowItWorksImgColumn>
      </Flex>
    </Section>

    <Section padding="sm">
      <TryItNowCTA
        className={howItWorksStyles.tryItNowContent}
        title={
          <>
            Try it now
            <br /> for free
          </>
        }
        desc={
          'Build and run an Experience today. Create as many missions as you can muster, invite up to 3 teams, and watch the magic happen.'
        }
      />
    </Section>
  </Page>
);

export default HIWPlaying;
