import React from 'react';
import classNames from 'classnames';
import styles from './HowItWorks.module.scss';

type Props = React.PropsWithChildren<{
  align?: 'left' | 'right' | 'center';
  padLeft?: boolean;
  padRight?: boolean;
  removeBotPadding?: boolean;
  overflowVisible?: boolean;
}>;

const HowItWorksImgColumn = (props: Props) => (
  <div
    className={classNames(styles.column, {
      [styles.columnPadLeft]: props.padLeft,
      [styles.columnPadRight]: props.padRight,
      [styles.columnRemoveBotPadding]: props.removeBotPadding,
      [styles.columnAlignCenter]: props.align === 'center',
      [styles.columnAlignRight]: props.align === 'right',
      [styles.overflowVisible]: props.overflowVisible,
    })}
  >
    {props.children}
  </div>
);

export default HowItWorksImgColumn;
